import { Box, Stack, Typography } from "@mui/material";
import type { Control } from "react-hook-form";

import { InformativeTextPopover } from "@/components/common/InformativeTextPopover";
import {
  HighlightController,
  HighlightTypes,
} from "@/components/high-risks/exposure/controller/HighlightController";
import { GRAPH_TITLE, GRAPH_TOOLTIP } from "@/constants/risk-exposure";

interface Props {
  byHazard?: boolean;
  control: Control<any>;
}

export const RiskExposureHeader = (props: Props) => {
  const { byHazard, control } = props;

  const key = byHazard ? "byHazard" : "byGroup";

  return (
    <Stack direction="row" spacing={1} pb={1} justifyContent="space-between">
      <Box display="flex" gap={1}>
        <Typography variant="h3">{GRAPH_TITLE[key]}</Typography>
        <InformativeTextPopover
          text={GRAPH_TOOLTIP[key]}
          hover
          isInfo
          width="25%"
          multiline
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" marginRight="15px">
          Highlight:
        </Typography>
        <HighlightController
          control={control}
          highlightType={HighlightTypes.tolerable}
        />
        <HighlightController
          control={control}
          highlightType={HighlightTypes.unacceptable}
          label="Unacceptable"
        />
      </Box>
    </Stack>
  );
};
