import type { FC } from "react";
import React from "react";

import { Typography, Box } from "@mui/material";
import type { BarItemProps, BarDatum, BarLayer } from "@nivo/bar";
import dynamic from "next/dynamic";

const ResponsiveBar = dynamic(
  () => import("@nivo/bar").then((m) => m.ResponsiveBar),
  { ssr: false }
);

interface IMarginForGraph {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
interface IBarGraphProps {
  title?: string;
  keys: string[];
  colors?: string[];
  chartData: any;
  hasLegend?: boolean;
  height?: number;
  indexBy: string;
  margin?: IMarginForGraph;
  barCountTreshold?: number | number[];
  titleStyling?: any;
  legends?: any;
  padding?: number;
  axisBottom?: any;
  axisLeft?: any;
  tooltip?: any;
  layout?: "horizontal" | "vertical";
  enableGridX?: boolean;
  enableGridY?: boolean;
  barComponent?: FC<BarItemProps<BarDatum>>;
  barTheme?: object;
  layers?: BarLayer<BarDatum>[];
}

export const BarGraph = (props: IBarGraphProps) => {
  const {
    title,
    keys,
    colors,
    chartData,
    height,
    indexBy,
    margin,
    barCountTreshold,
    titleStyling,
    legends,
    padding,
    axisBottom,
    axisLeft,
    tooltip,
    layout,
    barComponent,
    enableGridX = true,
    enableGridY = false,
    barTheme,
    layers,
  } = props;

  const heightOfChart =
    height ??
    Math.max(
      chartData.length > 10 ? chartData.length * 45 : chartData.length * 70,
      200
    );

  return (
    <Box
      sx={{
        height: `${heightOfChart < 75 ? 75 : heightOfChart}px`,
        "& line[y2='5']": {
          display: "none",
        },
        "& line[y2='0']": {
          display: "none",
        },
      }}
    >
      {title && <Typography sx={titleStyling}>{title}</Typography>}

      <ResponsiveBar
        barComponent={barComponent}
        data={chartData}
        keys={keys}
        indexBy={indexBy}
        margin={
          margin ?? {
            top: 50,
            right: 20,
            bottom: 80,
            left: 200,
          }
        }
        padding={padding ?? 0.5}
        valueScale={{ type: "linear" }}
        colors={colors}
        layout={layout ?? "horizontal"}
        borderColor="inherit:darker(1.6)"
        enableLabel={false}
        enableGridX={enableGridX}
        gridXValues={barCountTreshold ?? 10}
        enableGridY={enableGridY}
        gridYValues={barCountTreshold ?? 10}
        theme={barTheme}
        layers={layers}
        legends={
          legends ?? [
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "row",
              itemsSpacing: 2,
              itemWidth: 160,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              translateY: -35,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
        }
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        tooltip={tooltip}
      />
    </Box>
  );
};
