import type { SxProps } from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ScalingMetricEnum } from "@/components/high-risks/types";

interface Props {
  onChange: () => void;
  value: ScalingMetricEnum;
  styles?: SxProps;
  hideIcon?: boolean;
}

export const ScalingMetricSelect = ({
  onChange,
  value,
  styles,
  hideIcon,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select
        size="small"
        labelId="scaling-metric"
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
        id="scaling-metric-select"
        onChange={onChange}
        value={value}
        defaultValue={ScalingMetricEnum.AssetCount}
        sx={styles}
      >
        {Object.values(ScalingMetricEnum).map((metric, i) => (
          <MenuItem key={metric + i} value={metric}>
            {t(metric)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
