import { useContext, useMemo, useRef, useState } from "react";

import type { Theme } from "@mui/material";
import { Stack, useTheme } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type { RCPScenarioEnum } from "@/apis/services/HazardService";
import {
  RiskRatingConsequenceType,
  RiskRatingGrouping,
  AssessmentTypeEnum,
} from "@/apis/services/HazardService";
import { ReferenceTypeEnum } from "@/apis/services/HazardService";
import type { ConsequenceUnion } from "@/components/asset/filter/ConsequenceFilter";
import type { HazardUnion } from "@/components/asset/filter/HazardFilter";
import { BarGraph } from "@/components/common/graph/BarGraph";
import { CustomNameOnAxis } from "@/components/common/graph/CustomNameOnAxis";
import { OrganizationSettingsContext } from "@/components/context/organizationSettingsContext";
import { riskRatingMapping } from "@/components/forms/organization/RiskThresholdSetting";
import { HighlightTypes } from "@/components/high-risks/exposure/controller/HighlightController";
import {
  calculatePaddingForBarWidth,
  combineAssetDataWithRiskData,
  combineAssetWithIrisGroups,
  combineAssetWithTopHazards,
  filterByConsequence,
  filterByHazard,
  getDefaultConsequence,
  getDefaultHazard,
  getScalingUnit,
  getUniqueValuesFromObjectArray,
  isMultiHazardSelected,
  scaleRiskRatingCount,
} from "@/components/high-risks/exposure/risk-exposure-graph-utils";
import { RiskExposureBarBorderLayer } from "@/components/high-risks/exposure/RiskExposureBarBorderLayer";
import { RiskExposureControls } from "@/components/high-risks/exposure/RiskExposureControls";
import { RiskExposureHeader } from "@/components/high-risks/exposure/RiskExposureHeader";
import { HighRisksTooltip } from "@/components/high-risks/HighRisksTooltip";
import { RiskCountByHazardEmpty } from "@/components/high-risks/RiskCountByHazardEmpty";
import { RiskCountByHazardError } from "@/components/high-risks/RiskCountByHazardError";
import { RiskCountByHazardLoading } from "@/components/high-risks/RiskCountByHazardLoading";
import type {
  RiskExposureChartData,
  ScalingMetricEnum,
} from "@/components/high-risks/types";
import { ClassificationMetricEnum } from "@/components/high-risks/types";
import { classOneRatingColors } from "@/components/risk-ratings/ClassOneRiskRatings";
import { useAllGroupsForAssets } from "@/hooks/useAllGroupsForAssets";
import { useGetRiskExposureRatings } from "@/hooks/useRiskExposureModule";
import type { GetTopHazardQuery } from "@/hooks/useRiskRatings";
import { useGetTopHazards } from "@/hooks/useRiskRatings";
import { getHazardDisplayLabel } from "@/utils/display-label-utils";
import { formatNumber } from "@/utils/utils";

interface Props {
  assets?: AssetBasicResponse[];
  groupName?: string;
  byHazard?: boolean;
  direction?: "vertical" | "horizontal";
}

const CHART_HEIGHT = 450;

const barTheme = (theme: Theme) => ({
  axis: {
    legend: {
      text: {
        fontSize: 12,
        fill: `${theme.palette.text.secondary}`,
      },
    },
    ticks: {
      line: {
        stroke: `${theme.palette.text.secondary}`,
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fill: `${theme.palette.text.secondary}`,
      },
    },
  },
});

export const RiskExposureBarGraph = (props: Props) => {
  const { assets, groupName, byHazard, direction = "vertical" } = props;

  const assetIds = assets?.map(({ id }) => id) ?? [];
  const isGroup = !!groupName;
  const testId = `risk-exposure-graph${byHazard ? "-by-hazard" : ""}`;

  const { t } = useTranslation();
  const theme = useTheme();

  const ref = useRef(null);

  const { organizationSettings, flags } = useContext(
    OrganizationSettingsContext
  );
  const defaultHazard = getDefaultHazard(flags, byHazard);
  const defaultConsequence = getDefaultConsequence(flags, byHazard);

  const form = useForm({
    defaultValues: {
      "scaling-metric": "asset_count" as ScalingMetricEnum,
      "classification-metric": byHazard
        ? ("hazard" as ClassificationMetricEnum)
        : ("ownership" as ClassificationMetricEnum),
      "hazard-filter": defaultHazard.split(",") as HazardUnion[],
      "consequence-filter": [defaultConsequence] as ConsequenceUnion[],
      "climate-scenario-filter": "all",
      "time-horizon-filter": "all",
      highlight: undefined as HighlightTypes | undefined,
    },
  });
  const scalingMetric = form.watch("scaling-metric");
  const classificationMetric = form.watch("classification-metric");

  const hazardValue = form.watch("hazard-filter");
  const isMultiHazard = isMultiHazardSelected(hazardValue);

  const consequenceValue = form.watch("consequence-filter");

  const climateScenarioValue = form.watch("climate-scenario-filter");
  const climateScenarioFilter =
    climateScenarioValue === "all" ? undefined : climateScenarioValue;

  const timeHorizonValue = form.watch("time-horizon-filter");
  const timeHorizonFilter =
    timeHorizonValue === "all" || climateScenarioValue === "all"
      ? undefined
      : timeHorizonValue;

  const assessmentType =
    climateScenarioValue === "all"
      ? AssessmentTypeEnum.CURRENT
      : AssessmentTypeEnum.FUTURE;

  const highlightValue = form.watch("highlight");

  const thresholds: any = // RiskThresholdSetting
    organizationSettings.views.portfolio.settings.risk_threshold ?? {};
  const threshold = thresholds[consequenceValue?.[0]];

  const riskRatingValues = Object.values(riskRatingMapping);
  const unacceptableRating = threshold?.unacceptable ?? 5;
  const unacceptable = riskRatingValues.splice(
    unacceptableRating,
    riskRatingValues.length - 1 - unacceptableRating
  );

  const tolerableRating = threshold?.tolerable ?? 2;
  const tolerable = riskRatingValues.splice(
    tolerableRating,
    riskRatingValues.length - 1 - tolerableRating
  );

  const {
    riskRatings: highTide,
    isLoading: isHighTideLoading,
    isError: highTideError,
  } = useGetRiskExposureRatings(
    assets,
    {
      hazards: hazardValue,
      consequences: consequenceValue,
    },
    {
      assessmentType: assessmentType,
      climateScenario: climateScenarioFilter as RCPScenarioEnum,
      timeHorizon: timeHorizonFilter as any,
    },
    isMultiHazard ? RiskRatingGrouping.CONSEQUENCE : RiskRatingGrouping.HAZARD
  );

  const {
    data: groups,
    isLoading: groupsLoading,
    isError: groupsError,
  } = useAllGroupsForAssets(!isGroup || !byHazard, assetIds);

  // Generating data for Asset Risk Count By Top Hazard Chart.
  const query: GetTopHazardQuery = {
    ref_type: ReferenceTypeEnum.ASSET,
    assessment_type: assessmentType,
    rcp_scenario: climateScenarioFilter as RCPScenarioEnum,
    time_horizon: timeHorizonFilter as any,
  };
  const { data: topHazards, isError: hazardsError } = useGetTopHazards(query, {
    ref_ids: byHazard ? assetIds : [],
  });

  const [chartData, setChartData] = useState<RiskExposureChartData[]>();

  // TODO split this up so we dont have to do everything all the time
  useMemo(() => {
    if (!assets) return;

    const riskData = highTide ?? [];
    let combinedAssetRiskData = combineAssetDataWithRiskData(assets, riskData);

    if (classificationMetric === ClassificationMetricEnum.IrisGroup) {
      const groupNames = groups?.map((o) => o.groups.map((x) => x.name)) ?? [];
      combinedAssetRiskData = combineAssetWithIrisGroups(
        combinedAssetRiskData,
        groupNames,
        groupName
      );
    }

    const isHazard = classificationMetric === ClassificationMetricEnum.Hazard;
    if (isHazard && topHazards && topHazards.length) {
      const topHazardData = combineAssetWithTopHazards(
        topHazards,
        combinedAssetRiskData
      );
      combinedAssetRiskData = topHazardData;
    }

    const filteredByHazard = filterByHazard(combinedAssetRiskData, hazardValue);
    const filteredByConsequence = filterByConsequence(
      filteredByHazard,
      consequenceValue
    );
    const withoutHazardRatings = filteredByConsequence.filter(
      (a) => a.consequence !== RiskRatingConsequenceType.HAZARD_RATING
    );
    combinedAssetRiskData = withoutHazardRatings;

    const uniqueKeys = getUniqueValuesFromObjectArray(
      classificationMetric,
      combinedAssetRiskData.reverse()
    );

    const categorizedData = uniqueKeys.map((uniqueKey) =>
      combinedAssetRiskData.filter(
        (combinedAsset) => combinedAsset[classificationMetric] === uniqueKey
      )
    );

    const riskExposureChartData = uniqueKeys.map((key, i) => ({
      ...(scaleRiskRatingCount(
        scalingMetric,
        categorizedData[i]
      ) as RiskExposureChartData),
      [classificationMetric]: key,
    }));

    setChartData(riskExposureChartData);
  }, [
    highTide,
    topHazards,
    groups,
    scalingMetric,
    classificationMetric,
    hazardValue,
    consequenceValue,
    climateScenarioValue,
    timeHorizonValue,
    highTide,
  ]);

  const isLoading = groupsLoading || isHighTideLoading;
  const isEmpty = !chartData || !chartData.length;
  const isError = !assets || groupsError || highTideError || hazardsError;

  if (isLoading) return <RiskCountByHazardLoading />;
  if (isError) return <RiskCountByHazardError />;

  return (
    <FormProvider {...form}>
      <Stack spacing={1} data-test={testId}>
        <RiskExposureHeader byHazard={byHazard} control={form.control} />
        <RiskExposureControls isHazardGraph={byHazard ?? false} />
        {isEmpty && <RiskCountByHazardEmpty />}
        {!isEmpty && (
          <div ref={ref}>
            <BarGraph
              keys={Object.keys(classOneRatingColors)}
              colors={Object.values(classOneRatingColors).map((c) => c.color)}
              chartData={chartData}
              layout={direction ?? "vertical"}
              indexBy={classificationMetric}
              enableGridX={direction === "horizontal"}
              enableGridY={true}
              barCountTreshold={6}
              height={CHART_HEIGHT} //make responsive
              margin={{
                top: direction === "horizontal" ? 0 : 25,
                right: direction === "horizontal" ? 90 : 0,
                bottom: 70,
                left: direction === "horizontal" ? 160 : 65,
              }}
              legends={[]}
              barTheme={barTheme(theme)}
              padding={calculatePaddingForBarWidth(
                direction === "vertical"
                  ? ref.current
                    ? ref.current["clientWidth"]
                    : 450
                  : CHART_HEIGHT,
                chartData.length,
                chartData.length > 15 ? 10 : 25
              )}
              tooltip={(props: any) =>
                HighRisksTooltip({
                  ...props,
                  value: `${formatNumber(props.value)} ${getScalingUnit(
                    scalingMetric,
                    props.value
                  )}`,
                })
              }
              layers={[
                "axes",
                "grid",
                "bars",
                (props) =>
                  highlightValue === HighlightTypes.unacceptable ? (
                    <RiskExposureBarBorderLayer
                      bars={props.bars}
                      risks={unacceptable}
                      isHorizontal={byHazard}
                      unit={getScalingUnit(scalingMetric)}
                    />
                  ) : null,
                (props) =>
                  highlightValue === HighlightTypes.tolerable ? (
                    <RiskExposureBarBorderLayer
                      bars={props.bars}
                      risks={tolerable}
                      isHorizontal={byHazard}
                      unit={getScalingUnit(scalingMetric)}
                    />
                  ) : null,
              ]}
              axisLeft={
                direction === "horizontal"
                  ? {
                      renderTick: (props: any) =>
                        CustomNameOnAxis(
                          props,
                          true,
                          byHazard
                            ? getHazardDisplayLabel(props.value)
                            : undefined,
                          theme.palette.text.secondary
                        ),
                    }
                  : {
                      format: (v: number) => formatNumber(v),
                      tickValues: 5,
                      legend: `${t(scalingMetric)} (${getScalingUnit(
                        scalingMetric
                      )})`,
                      legendPosition: "middle",
                      legendOffset: -55,
                    }
              }
              axisBottom={
                direction === "horizontal"
                  ? {
                      format: (v: number) => formatNumber(v),
                      tickValues: 5,
                      legend: `${t(scalingMetric)} (${getScalingUnit(
                        scalingMetric
                      )})`,
                      legendPosition: "middle",
                      legendOffset: 40,
                    }
                  : {
                      renderTick: (props: any) =>
                        CustomNameOnAxis(
                          props,
                          false,
                          byHazard
                            ? getHazardDisplayLabel(props.value)
                            : undefined,
                          theme.palette.text.secondary
                        ),
                    }
              }
            />
          </div>
        )}
      </Stack>
    </FormProvider>
  );
};
