import { useContext } from "react";

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type { RatingInfo } from "@/apis/services/HazardService";
import { RiskRatingConsequenceType } from "@/apis/services/HazardService";
import { ChipRatingBar } from "@/components/common/ChipRatingBar";
import { OrganizationSettingsContext } from "@/components/context/organizationSettingsContext";
import { RiskRatingIcon } from "@/components/risk-ratings/RiskRatingIcon";
import { getDescriptionByConsequence } from "@/constants/rating-description";
import { useSearchRiskRatingFunction } from "@/hooks/useRiskRatings";
import { getHazardDisplayLabel } from "@/utils/display-label-utils";

import { HighRisksTableEmpty } from "./HighRisksTableEmpty";
import { HighRisksTableError } from "./HighRisksTableError";
import { HighRisksTableHeader } from "./HighRisksTableHeader";
import { HighRisksTableLoading } from "./HighRisksTableLoading";
import { TableCellHeader } from "./table/TableCellHeader";

type RowEntry = { name: string } & RatingInfo;

const RISK_RATING_TOOLIP =
  "Qualitative description of risk for a specific asset, hazard, and consequence type.\n This is based on the long-term average risk and the client's risk tolerance.";
const CONSEQUENCE_TOOLTIP =
  "Long-term average risk over a 10-year span. \nFor example, if a 500-year event is expected to cause 200 days of downtime, then the long-term average risk is (10 years / 500 years) * 200 days = 4 days over a 10-year span.";

export interface HighRisksTableParameters {
  groupId?: string;
  assets?: AssetBasicResponse[] | any[];
}

export const HighRisksTable = (props: HighRisksTableParameters) => {
  const { groupId, assets } = props;
  const router = useRouter();

  const { organizationSettings } = useContext(OrganizationSettingsContext);

  const {
    data: riskRatings,
    isLoading,
    isError,
  } = useSearchRiskRatingFunction({
    hazards: [],
    consequences: [],
    ref_ids: assets?.map(({ id }) => id) as string[],
  });

  const riskRatingsAboveThreshold =
    riskRatings
      ?.filter(
        (rating: any) =>
          rating.consequence !== RiskRatingConsequenceType.HAZARD_RATING &&
          rating.consequence !==
            RiskRatingConsequenceType.HEALTH_AND_WELLNESS &&
          rating.consequence !==
            RiskRatingConsequenceType.SEVERE_WEATHER_WARNING
      )
      .filter((check) => {
        // get the correct consequence threshold for this rating
        const thresholds: any =
          organizationSettings.views.portfolio.settings.risk_threshold ?? {};
        const consequenceThreshold = thresholds[check.consequence];
        const unacceptable = consequenceThreshold?.unacceptable ?? 5;
        return check.risk_sort_score >= unacceptable;
      }) ?? [];

  const rowsGroupedByAssets = Object.values(
    riskRatingsAboveThreshold.reduce((acc, rating) => {
      acc[rating.ref_id] = acc[rating.ref_id] || [];

      const asset = assets?.find((asset) => asset.id === rating.ref_id);
      const name = asset?.name ?? "Asset name could not be found";
      acc[rating.ref_id].push({ name: name, ...rating });
      return acc;
    }, {} as Record<string, RowEntry[]>)
  );

  const onNameClick = (assetId: string) => {
    const from = `?from=${groupId ? "groups" : "/"}`;
    const _groupId = `${groupId ? "&groupId=" + groupId : ""}`;

    router.push(`/assets/${assetId}${from}${_groupId}`);
  };

  if (isLoading) return <HighRisksTableLoading />;
  if (riskRatingsAboveThreshold.length === 0) return <HighRisksTableEmpty />;
  if (isError) <HighRisksTableError />;

  return (
    <Stack maxHeight={750}>
      <HighRisksTableHeader
        shown={rowsGroupedByAssets.length}
        total={assets?.length ?? 0}
      />

      <TableContainer
        data-test="high-risks-table"
        component={Paper}
        sx={{
          boxShadow: "none",
          overflowX: { xl: "hidden " },
        }}
      >
        <Table size="small" aria-label="high risk table">
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "15%" }} />
            <col style={{ width: "45%" }} />
          </colgroup>
          <TableHead>
            <TableRow
              sx={{
                display: { md: "table-row", xl: "table-row" },
                flexWrap: "no-wrap",
              }}
            >
              <TableCellHeader text="Asset" />
              <TableCellHeader text="Hazard" />
              <TableCellHeader text="Risk rating" info={RISK_RATING_TOOLIP} />
              <TableCellHeader text="Consequence" info={CONSEQUENCE_TOOLTIP} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsGroupedByAssets.map((rows: RowEntry[], i: number) => {
              return (
                <TableRow
                  key={`${rows[0].ref_id}-${i}`}
                  onClick={() => onNameClick(rows[0].ref_id)}
                  sx={{
                    cursor: "pointer",
                    display: "table-row",
                    flexWrap: "no-wrap",
                  }}
                >
                  <TableCell sx={{ verticalAlign: "top" }}>
                    <Stack sx={{ pt: 1 }}>
                      <Typography variant="body2">{rows[0].name}</Typography>
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack spacing={0.5} sx={{ pt: 1 }}>
                      {rows.map((row, i) => {
                        return (
                          <Stack
                            key={`${row.hazard}_${row.consequence}_${i}`}
                            direction="row"
                            spacing={1}
                            sx={{ height: "24px" }}
                          >
                            <RiskRatingIcon
                              icon={row.hazard!}
                              disableTooltip={true}
                            />
                            <Typography variant="body2" noWrap>
                              {getHazardDisplayLabel(row.hazard!)}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack spacing={0.5}>
                      {rows.map((row, i) => {
                        return (
                          <ChipRatingBar
                            key={`${row.ref_id}_${row.consequence}_${i}`}
                            data={row.risk_rating || "Not Available"}
                            consequence={row.consequence}
                            size="small"
                          />
                        );
                      })}
                    </Stack>
                  </TableCell>

                  <TableCell>
                    <Stack spacing={0.5} sx={{ pt: 1 }}>
                      {rows.map((row, i) => {
                        const options = {
                          rating: row.risk_rating,
                          consequence: row.consequence,
                        };
                        const description =
                          getDescriptionByConsequence(options);
                        const text =
                          description ||
                          `No consequence information found for: ${row.consequence}`;

                        return (
                          <Stack
                            key={`${row.ref_id}_${row.consequence}_${i}`}
                            direction="row"
                            sx={{ height: "24px" }}
                          >
                            <Typography variant="body2" noWrap>
                              {text}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
