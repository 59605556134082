import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons/faSquarePollHorizontal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

interface Props {
  text?: string;
  byHazard?: boolean;
}

export const RiskCountByHazardEmpty = (props: Props) => {
  const { text, byHazard } = props;

  const testId = `risk-count${byHazard ? "-by-hazard" : ""}-empty`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight={200}
      width="100%"
      sx={{ pt: 1 }}
      data-test={testId}
    >
      <FontAwesomeIcon size="3x" icon={faSquarePollHorizontal} color="grey" />
      <Typography fontWeight="300" fontStyle="italic" pt={2}>
        {text ?? "There are no results available."}
      </Typography>
    </Box>
  );
};
