import { FormControlLabel, Switch, Typography } from "@mui/material";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

export enum HighlightTypes {
  unacceptable = "unacceptable",
  tolerable = "tolerable",
}

interface Props {
  control: Control<any>;
  highlightType?: HighlightTypes;
  label?: string;
}

export const HighlightController = ({
  control,
  highlightType,
  label,
}: Props) => {
  return (
    <Controller
      control={control}
      name="highlight"
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          control={
            <Switch
              size="small"
              onChange={(e, checked) =>
                onChange(checked ? highlightType : null)
              }
              checked={highlightType && value === highlightType}
            />
          }
          label={
            <Typography variant="body2">{label ?? "Tolerable"}</Typography>
          }
        />
      )}
    />
  );
};
