import { Typography } from "@mui/material";

interface HiddenLabelProps {
  total: number;
}

const MAX_CHIPS = 20;

export const HiddenLabel = (props: HiddenLabelProps) => {
  const { total } = props;

  if (total < MAX_CHIPS) return <></>;

  const label = total - MAX_CHIPS;
  return <Typography variant="caption">{`${label} hidden`}</Typography>;
};
