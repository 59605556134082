import type { SxProps } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ClassificationMetricEnum } from "@/components/high-risks/types";

interface Props {
  onChange: () => void;
  value: ClassificationMetricEnum;
  styles?: SxProps;
  hideIcon?: boolean;
}

export const ClassificationMetricSelect = ({
  onChange,
  value,
  styles,
  hideIcon,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Select
        size="small"
        labelId="classification-metric"
        onChange={onChange}
        value={value}
        defaultValue={Object.values(ClassificationMetricEnum)[0]}
        sx={styles}
        inputProps={hideIcon ? { IconComponent: () => null } : undefined}
      >
        {Object.values(ClassificationMetricEnum).map(
          (metric, i) =>
            metric !== ClassificationMetricEnum.Hazard && (
              <MenuItem key={metric + i} value={metric}>
                {t(metric)}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};
