import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { ScalingMetricSelect } from "@/components/high-risks/exposure/ScalingMetricSelect";

interface Props {
  control: Control;
}

export const ScalingMetricController = ({ control }: Props) => (
  <Controller
    control={control}
    name="scaling-metric"
    render={({ field: { onChange, value } }) => (
      <ScalingMetricSelect
        styles={HoverTextSelectStyles()}
        value={value}
        onChange={onChange}
        hideIcon
      />
    )}
  />
);
