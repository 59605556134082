import React from "react";

import { Typography, Stack, Box, Paper } from "@mui/material";

import { getHazardDisplayLabel } from "@/utils/display-label-utils";

export const HighRisksTooltip = (props: any) => {
  const { id, value, color, indexValue } = props;
  return (
    <Paper variant="outlined">
      <Stack direction="row" padding={0.75} alignItems="center" spacing={1}>
        <Box height={15} width={15} sx={{ background: color }} />
        <Typography component="h4">
          {id} - {getHazardDisplayLabel(indexValue)} : {value}
        </Typography>
      </Stack>
    </Paper>
  );
};
