import { TableCell, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

interface TableCellDividerProps {
  text: string;
  span?: number;
}

export const TableCellDivider = (props: TableCellDividerProps) => {
  const { text, span } = props;
  const theme = useTheme();
  return (
    <TableCell
      key={`cell_divider_${text}`}
      colSpan={span}
      style={{
        textAlign: "center",
        borderLeft: "1px solid lightgrey",
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        color={theme.palette.link.main}
      >
        {text}
      </Typography>
    </TableCell>
  );
};
