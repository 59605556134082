import { useTheme } from "@mui/material";

export const HoverTextSelectStyles = () => {
  const theme = useTheme();

  return {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `dashed 1px ${theme.palette.link.main}!important`,
      backgroundColor: `color-mix(in srgb,${theme.palette.link.main},#0000 75%);`,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `dashed 1px ${theme.palette.link.main}!important`,
      backgroundColor: `color-mix(in srgb,${theme.palette.link.main},#0000 75%);`,
    },
    ".MuiSelect-outlined": {
      paddingRight: "5px!important",
      paddingLeft: "5px!important",
      paddingTop: "4px!important",
      paddingBottom: "4px!important",
      textOverflow: "inherit",
      color: `${theme.palette.link.main}!important`,
      fontWeight: "bold",
      "& .MuiTypography-root": { fontWeight: "bold" },
    },
    marginRight: "2px",
    marginLeft: "2px",
  };
};
