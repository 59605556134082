import type { Control } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";

import { RCPScenarioEnum } from "@/apis/services/HazardService";
import { getOptions } from "@/components/asset/filter/filter-utils";
import { GenerateDropdown } from "@/components/common/dropdown/GenerateDropdown";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useRiskRatingsOpenSearchClimateScenarios } from "@/hooks/useRiskRatingsOpenSearch";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

interface Props {
  control: Control;
}

export const ClimateScenarioFilterController = ({ control }: Props) => {
  const { data: id } = useUserInfo();

  const { setValue } = useFormContext();

  const styles = HoverTextSelectStyles();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const { data: climateScenarios, isLoading: isClimateScenarioLoading } =
    useRiskRatingsOpenSearchClimateScenarios();

  const climateScenarioOptions = getOptions(
    Object.values(RCPScenarioEnum),
    useOpenSearch ? climateScenarios?.map((cs) => cs.key) : []
  );

  return (
    <Controller
      control={control}
      name="climate-scenario-filter"
      render={({ field: { onChange, value } }) => (
        <GenerateDropdown
          value={value}
          onChange={(e) => {
            const value = e.target.value;
            // if climate scenario all is selected the time horizon
            // option will always be all as well so reset it here
            if (value === "all") setValue("time-horizon-filter", "all");
            onChange(value);
          }}
          options={climateScenarioOptions}
          label="Climate Scenario"
          styles={{ ...styles }}
          width="auto"
          hideIcon={true}
          allOptionsLabel="allClimateScenarios.lower"
          isLoading={isClimateScenarioLoading}
        />
      )}
    />
  );
};
