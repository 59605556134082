import useSWR from "swr";

import { FIVE_MINUTES } from "@/constants/session";

import { useServices } from "./useServices";

export const useAllGroupsForAssets = (
  shouldFetch: boolean,
  asset_ids: string[]
) => {
  const { asset } = useServices();

  const doCall = shouldFetch && asset_ids.length > 0;
  const key = `useAllGroupsForAssets-${asset_ids.sort().join(",")}`;
  const { data, error } = useSWR(
    doCall ? key : null,
    async () => {
      const assets = await asset.assets.getGroupsForAssets({
        asset_ids: asset_ids,
      });
      return assets.data;
    },
    { dedupingInterval: FIVE_MINUTES }
  );

  return {
    data: data,
    isLoading: doCall && !error && !data,
    isError: error,
  };
};
