import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { ClassificationMetricController } from "@/components/high-risks/exposure/controller/ClassificationMetricController";
import { ClimateScenarioFilterController } from "@/components/high-risks/exposure/controller/ClimateScenarioFilterController";
import { ConsequenceFilterController } from "@/components/high-risks/exposure/controller/ConsequenceFilterController";
import { HazardFilterController } from "@/components/high-risks/exposure/controller/HazardFilterController";
import { ScalingMetricController } from "@/components/high-risks/exposure/controller/ScalingMetricController";
import { TimeHorizonFilterController } from "@/components/high-risks/exposure/controller/TimeHorizonFilterController";

const boxStyles: any = {
  flexFlow: "wrap",
  textAlign: "center",
  alignItems: "center",
};

interface Props {
  isHazardGraph: boolean;
}

export const RiskExposureControls = ({ isHazardGraph }: Props) => {
  const { control } = useFormContext();

  return (
    <Box display="flex" sx={{ ...boxStyles }}>
      <Typography variant="body1">
        This table shows the risk exposure of
      </Typography>
      <ScalingMetricController control={control} />

      {isHazardGraph ? (
        <>
          <Typography variant="body1">for</Typography>
          <HazardFilterController
            control={control}
            isHazardGraph={isHazardGraph}
          />
          <Typography variant="body1">. Risks are shown for</Typography>
        </>
      ) : (
        <>
          <Typography variant="body1">grouped by</Typography>
          <ClassificationMetricController control={control} />
          <Typography variant="body1">. </Typography>
          <HazardFilterController
            control={control}
            isHazardGraph={isHazardGraph}
          />
          <Typography variant="body1">is shown for</Typography>
        </>
      )}

      <ConsequenceFilterController
        control={control}
        isHazardGraph={isHazardGraph}
      />
      <Typography variant="body1"> in</Typography>
      <ClimateScenarioFilterController control={control} />
      <Typography variant="body1"> at </Typography>
      <TimeHorizonFilterController control={control} />
      <Typography variant="body1">.</Typography>
    </Box>
  );
};
