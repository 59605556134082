import type { PropsWithChildren } from "react";

import { Box, Paper } from "@mui/material";

import { assetHeaderStyles } from "@/styles/AppStyle";

export const AssetHeader = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        ...assetHeaderStyles,
      }}
    >
      <Paper
        color="inherit"
        sx={{
          height: 66,
          position: "relative",
          // This prevents moving items inside the app bar due to the scrollbar
          // See https://github.com/mui/material-ui/issues/10000#issuecomment-394837390
          px: "0px !important",
          pt: 1,
          overflowX: "auto",
          borderBottomRightRadius: 8,
          borderBottomLeftRadius: 8,
        }}
        elevation={0}
      >
        {children}
      </Paper>
    </Box>
  );
};
