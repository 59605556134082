import { ErrorAnalytics } from "@/components/assessment/views/ErrorAnalytics";

export const HighRisksTableError = () => {
  return (
    <ErrorAnalytics
      error={{
        message:
          "There was an error while getting the information for the assets.",
      }}
    />
  );
};
