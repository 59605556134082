export const GRAPH_TITLE: any = {
  byHazard: "Risk exposure by hazard",
  byGroup: "Risk exposure by group",
};

export const GRAPH_TOOLTIP: any = {
  byHazard:
    "Shows the risk exposure by hazard for important attributes of the organization (e.g., asset count, population, monthly revenue, etc.). Note that not all available consequences are relevant for each attribute. \n\nFor example, if population is the selected attribute, the individual life safety risk would be the most relevant consequence; downtime may also be relevant if population is the selected attribute, but repair costs would not be as relevant.",
  byGroup:
    "Shows the risk exposure by hazard for important attributes of the organization (e.g., asset count, population, monthly revenue, etc.). Assets can be grouped by pre-assigned group types. \n\nNote that if Multi-hazard (cumulative) risk is selected as the hazard, the risk rating corresponds to the aggregated risk aggregated across all assessed hazards.",
};
