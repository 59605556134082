import { useContext, useState } from "react";

import {
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  Popover,
  Typography,
  Box,
} from "@mui/material";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import {
  AssessmentTypeEnum,
  RiskRatingEnum,
} from "@/apis/services/HazardService";
import { RiskRatingConsequenceType } from "@/apis/services/HazardService";
import { SettingNameEnum } from "@/apis/services/OrganizationService";
import { ConsequenceFilter } from "@/components/asset/filter/ConsequenceFilter";
import { HazardFilter } from "@/components/asset/filter/HazardFilter";
import type { QueryValues } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { RiskRCPScenarioTimeHorizonDropdown } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { OrganizationSettingsContext } from "@/components/context/organizationSettingsContext";
import { riskRatingMapping } from "@/components/forms/organization/RiskThresholdSetting";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { prepareListByGroup } from "@/components/high-risks/risk-ratings/helperFunctionsOfIndividualAssetRiskRating";
import { HAZARD_TYPES_BY_GROUP } from "@/components/high-risks/types";
import { GroupAssetPopUp } from "@/components/map/AssetPopUp/GroupAssetPopUp";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useGetAssetsRiskRatings } from "@/hooks/useHighTide";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

import { RiskDistributionHeaderCell } from "./distribution/RiskDistributionHeaderCell";
import { TableCellDivider } from "./distribution/TableCellDivider";
import { TableColumnChips } from "./distribution/TableColumnChips";
import { useHazardsAndConsequences } from "./useHazardsAndConsequences";

type PopupInfoProps =
  | (AssetBasicResponse & {
      risk: string;
      hazard: string;
      consequence: string;
      target: any;
    })
  | undefined;

export type CategoryDataProps = {
  [key: string]: object[];
};

type Props = {
  assets: AssetBasicResponse[];
  groupId?: string;
};

export const RelativeRiskDistributionPlot = ({ assets, groupId }: Props) => {
  const { data: id } = useUserInfo();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const [popupInfo, setPopupInfo] = useState<PopupInfoProps>();
  const [queryValues, setQueryValues] = useState<QueryValues>({
    climateScenario: "all",
    timeHorizon: "all",
    assessmentType: AssessmentTypeEnum.CURRENT,
  });

  const { organizationSettings } = useContext(OrganizationSettingsContext);
  const thresholds: any = // RiskThresholdSetting
    organizationSettings.views.portfolio.settings.risk_threshold ?? {};

  const {
    selectedHazards,
    selectedConsequences,
    selectedHazardsUi,
    selectedConsequencesUi,
    setHazardsAndConsequences,
  } = useHazardsAndConsequences({
    defaultConsequences: [RiskRatingConsequenceType.DOWNTIME],
  });

  const climateScenarioQuery =
    queryValues.climateScenario === "all"
      ? undefined
      : queryValues.climateScenario;
  const timeHorizonQuery =
    queryValues.timeHorizon === "all" ? undefined : queryValues.timeHorizon;

  const { data: rows } = useGetAssetsRiskRatings(
    assets,
    {
      hazards: selectedHazards,
      consequences: selectedConsequences as RiskRatingConsequenceType[],
    },
    {
      assessmentType: queryValues.assessmentType,
      climateScenario: climateScenarioQuery,
      timeHorizon: timeHorizonQuery,
    }
  );

  const handleClick = (asset: AssetBasicResponse, event: any) => {
    const info: PopupInfoProps = {
      ...asset,
      risk: (asset as any)[`${selectedHazards[0]}_${selectedConsequences[0]}`],
      hazard: selectedHazards[0],
      consequence: selectedConsequences[0],
      target: event?.target,
    };

    setPopupInfo(info);
  };

  const handleClose = () => {
    setPopupInfo(undefined);
  };

  const categoryData: CategoryDataProps = Object.values(RiskRatingEnum).reduce(
    (acc: any, category) => {
      acc[category] = [];
      return acc;
    },
    {}
  );
  const categoryKeys = Object.keys(categoryData).reverse();

  rows.forEach((row) => {
    const key = `${selectedHazards[0]}_${selectedConsequences[0]}`;
    const rating = row[key] || "Not Assessed";
    categoryData[rating]?.push(row);
  });

  const unacceptableRating =
    thresholds[selectedConsequences[0]]?.unacceptable ?? 5;
  const unacceptable = riskRatingMapping[unacceptableRating];
  const tolerableRating = thresholds[selectedConsequences[0]]?.tolerable ?? 2;
  const tolerable = riskRatingMapping[tolerableRating];

  const assessedCol = categoryKeys.length - 1;
  const unacceptableSpan = assessedCol - unacceptableRating;
  const acceptableSpan = tolerableRating;
  const tolerableSpan = assessedCol - (unacceptableSpan + acceptableSpan);

  return (
    <>
      <Stack
        justifyContent="space-between"
        marginTop={5}
        data-test="relative-risk-distribution"
      >
        <Typography variant="h2">Relative Risk distribution</Typography>
        <Stack direction="row" spacing={3}>
          <Box
            display="flex"
            style={{
              flexFlow: "wrap",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              This graph shows the relative risk distribution of assets for
            </Typography>
            <HazardFilter
              hazards={prepareListByGroup(
                Object.keys(HAZARD_TYPES_BY_GROUP),
                true
              )}
              value={selectedHazardsUi}
              onChange={(hazards) => setHazardsAndConsequences({ hazards })}
              options={{
                showAggregate: true,
                hideIcon: true,
                useOpenSearch: useOpenSearch,
              }}
              styles={{
                formStyles: {
                  minWidth: "0px!important",
                  maxWidth: "500px!important",
                },
                selectStyles: HoverTextSelectStyles(),
              }}
            />
            <Typography variant="body1">.</Typography>
            <ConsequenceFilter
              value={selectedConsequencesUi}
              onChange={(consequences) =>
                setHazardsAndConsequences({ consequences })
              }
              setting={SettingNameEnum.RiskRatingsGraph}
              options={{
                hideIcon: true,
                multiple: false,
                dataTest: "relative-risk-distribution-consequence-select",
                useOpenSearch: useOpenSearch,
              }}
              styles={{
                formStyles: {
                  minWidth: "0px!important",
                  maxWidth: "500px!important",
                },
                selectStyles: HoverTextSelectStyles(),
              }}
            />
            <Typography variant="body1">risks are shown for</Typography>
            <RiskRCPScenarioTimeHorizonDropdown
              queryValues={queryValues}
              setQueryValues={setQueryValues}
              options={{
                hideIcon: true,
                styles: HoverTextSelectStyles(),
                useOpenSearch: useOpenSearch,
              }}
            >
              <Typography variant="body1">at</Typography>
            </RiskRCPScenarioTimeHorizonDropdown>
            <Typography variant="body1">.</Typography>
          </Box>
        </Stack>
      </Stack>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableRow sx={{ borderBottom: "1px solid lightgrey" }}>
            {categoryKeys.map((category, i) => {
              if (category === unacceptable)
                return (
                  <TableCellDivider
                    key={`cell_divider${category}_${i}`}
                    text="Unacceptable"
                    span={unacceptableSpan}
                  />
                );
              if (category === tolerable)
                return (
                  <TableCellDivider
                    key={`cell_divider${category}_${i}`}
                    text="Tolerable"
                    span={tolerableSpan}
                  />
                );
              if (i === 1)
                return (
                  <TableCellDivider
                    key={`cell_divider${category}_${i}`}
                    text="Acceptable"
                    span={acceptableSpan}
                  />
                );
              if (i === 0)
                return (
                  <TableCell
                    key={`empty_${category}_${i}`}
                    style={{ border: "0px" }}
                  />
                );
            })}
          </TableRow>
          <TableRow>
            {categoryKeys.map((category) => (
              <RiskDistributionHeaderCell
                key={`header_${category}`}
                text={category}
              />
            ))}
          </TableRow>

          <TableRow>
            {categoryKeys.map((category, index) => {
              return (
                <TableCell
                  key={`chips_${category}_${index}`}
                  size="small"
                  style={{
                    verticalAlign: "top",
                    borderRight:
                      category === "Not Assessed" ? "1px solid lightgray" : "",
                    borderLeft:
                      unacceptable === category || tolerable === category
                        ? "1px solid lightgray"
                        : "",
                    borderBottom: "none",
                  }}
                >
                  <Stack spacing={1} alignItems="center">
                    <TableColumnChips
                      data={categoryData}
                      category={category}
                      handleClick={handleClick}
                    />
                  </Stack>
                </TableCell>
              );
            })}
          </TableRow>
        </Table>
      </TableContainer>
      <Popover
        open={!!popupInfo}
        onClose={handleClose}
        anchorEl={(popupInfo as any)?.target}
      >
        {popupInfo && (
          <GroupAssetPopUp asset={popupInfo as any} groupId={groupId} />
        )}
      </Popover>
    </>
  );
};
