import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { ClassificationMetricSelect } from "@/components/high-risks/exposure/ClassificationMetricSelect";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";

interface Props {
  control: Control;
}

export const ClassificationMetricController = ({ control }: Props) => (
  <Controller
    control={control}
    name="classification-metric"
    render={({ field: { onChange, value } }) => (
      <ClassificationMetricSelect
        styles={HoverTextSelectStyles()}
        value={value}
        onChange={onChange}
        hideIcon
      />
    )}
  />
);
