import { Chip } from "@mui/material";

import type { CategoryDataProps } from "@/components/high-risks/RelativeRiskDistributionPlot";
import { getRiskRatingColorsByLevel } from "@/components/risk-ratings/ClassOneRiskRatings";

import { HiddenLabel } from "./HiddenLabel";

interface TableColumnChipsProps {
  data: CategoryDataProps;
  category: string;
  handleClick: Function;
}

const MAX_CHIPS = 20;

export const TableColumnChips = (props: TableColumnChipsProps) => {
  const { data, category, handleClick } = props;
  const total = data[category].length;

  return (
    <>
      <HiddenLabel total={total} />
      {data[category].map((obj: any, index: number) => {
        if (index >= MAX_CHIPS) return null;
        return (
          <Chip
            key={obj.id}
            label={obj.name}
            onClick={(e) => handleClick(obj, e)}
            data-test={`column-chip-${category}`}
            style={{
              width: 90,
              color: "white",
              height: 20,
              backgroundColor: getRiskRatingColorsByLevel(category).color,
              borderRadius: 5,
            }}
          />
        );
      })}
    </>
  );
};
