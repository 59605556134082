import type { PropsWithChildren } from "react";

import { Stack } from "@mui/material";

export const ActionButtons = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" marginLeft="auto">
      {children}
    </Stack>
  );
};
