import type { Control } from "react-hook-form";
import { Controller, useWatch } from "react-hook-form";

import { getOptionsWithFallBack } from "@/components/asset/filter/filter-utils";
import { GenerateDropdown } from "@/components/common/dropdown/GenerateDropdown";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useRiskRatingsOpenSearchTimeHorizons } from "@/hooks/useRiskRatingsOpenSearch";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

interface Props {
  control: Control;
}

export const TimeHorizonFilterController = ({ control }: Props) => {
  const { data: id } = useUserInfo();

  const climateScenario = useWatch({
    control,
    name: "climate-scenario-filter",
  });

  const styles = HoverTextSelectStyles();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const { data: timeHorizons, isLoading: isTimeHorizonLoading } =
    useRiskRatingsOpenSearchTimeHorizons();

  const openSearchTimeHorizons = timeHorizons
    ?.map((th) => th.key)
    .filter((key) => !!key) as unknown as number[];

  const _timeHorizons = getOptionsWithFallBack(
    [2050, 2100],
    openSearchTimeHorizons,
    useOpenSearch
  );

  const timeHorizonOptions = climateScenario === "all" ? [] : _timeHorizons;

  return (
    <Controller
      control={control}
      name="time-horizon-filter"
      render={({ field: { onChange, value } }) => (
        <GenerateDropdown
          value={value}
          onChange={onChange}
          options={timeHorizonOptions}
          label="Time Horizon"
          allOptionsLabel="allTimeHorizons.lower"
          styles={{ ...styles }}
          width="auto"
          hideIcon={true}
          isLoading={isTimeHorizonLoading}
        />
      )}
    />
  );
};
