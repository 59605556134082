import useSWR from "swr";

import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type {
  RCPScenarioEnum,
  RatingInfo,
  RiskRatingBatchRequest,
  RiskRatingConsequenceType,
  RiskRatingHazardsEnum,
} from "@/apis/services/HazardService";
import { RiskRatingGrouping } from "@/apis/services/HazardService";
import { ReferenceTypeEnum } from "@/apis/services/HazardService";
import type { QueryValues } from "@/components/common/RiskRCPScenarioTimeHorizonDropdown";
import { isConsequenceAggregate } from "@/components/high-risks/exposure/risk-exposure-graph-utils";
import type { HighTideParams } from "@/components/high-risks/types";
import { AGGREGATE, ALL_CONSEQUENCES, ALL_HAZARDS } from "@/constants/risks";

import { useServices } from "./useServices";

const generateHightideKey = (
  ids: string[],
  asset: AssetBasicResponse,
  query?: any
) => {
  return [
    "generateHightideKey",
    ids?.join(","),
    JSON.stringify(Object.keys(asset)),
    JSON.stringify(query),
  ];
};

const generateSearchKey = (
  ids: string[],
  asset: AssetBasicResponse,
  sortedHighTideParams: any,
  query?: any
) => {
  return [
    "generateSearchKey",
    ids?.join(","),
    JSON.stringify(Object.keys(asset)),
    JSON.stringify(sortedHighTideParams),
    JSON.stringify(query),
  ];
};

export const useGetRiskExposureRatings = (
  assets?: AssetBasicResponse[],
  highTideParams?: HighTideParams,
  query?: QueryValues,
  groupBy?: RiskRatingGrouping
) => {
  const { hazard } = useServices();

  const { hazards, consequences } = highTideParams ?? {};
  const _assets = assets ?? [];

  const queryValues = {
    assessment_type: query?.assessmentType,
    rcp_scenario: query?.climateScenario as RCPScenarioEnum,
    time_horizon: query?.timeHorizon as number,
  };
  const highTideQuery = {
    ref_type: ReferenceTypeEnum.ASSET,
    group_by: groupBy ?? RiskRatingGrouping.CONSEQUENCE,
    ...queryValues,
  };

  const searchQuery: any = {
    ref_type: ReferenceTypeEnum.ASSET,
    ...queryValues,
  };

  const ref_ids = _assets.map((a) => a.id).sort();

  const filteredHazards =
    hazards?.filter((h) => h !== AGGREGATE && h !== ALL_HAZARDS) ?? [];

  const sortedParams = {
    hazards: filteredHazards.sort() || [],
    consequences: consequences?.sort() || [],
  };
  const getConsequenceAggregate = isConsequenceAggregate(consequences);

  let key = [];
  if (getConsequenceAggregate) {
    key = generateHightideKey(ref_ids, _assets[0], highTideQuery);
  } else {
    key = generateSearchKey(ref_ids, _assets[0], sortedParams, highTideQuery);
  }

  const { data, error, isLoading } = useSWR(
    _assets.length > 0 ? key : null,
    async () => {
      // Risk Ratings come from two separate api calls: HighTide and Search
      const consequences =
        sortedParams.consequences.filter((c) => c !== ALL_CONSEQUENCES) ?? [];

      // if we want aggregated results, we need to use the highTide api
      // - only do this if the aggregate option is selected from the filter
      const aggregate = getConsequenceAggregate
        ? hazard.riskRatings.getHighTideRiskRating(highTideQuery, {
            ref_ids: ref_ids,
          })
        : Promise.resolve({ data: [] as RatingInfo[] });

      // if we want specific hazard / consequence combinations we need to use the search api
      // - we only want to do the api call if we have selected hazards (besides aggregate)
      const body: RiskRatingBatchRequest = {
        ref_ids: ref_ids,
        hazards: sortedParams.hazards as RiskRatingHazardsEnum[],
        consequences: (consequences as RiskRatingConsequenceType[]) ?? [],
      };
      const assetHazards = !getConsequenceAggregate
        ? hazard.riskRatings.searchRiskRating(searchQuery, body)
        : Promise.resolve({ data: [] as RatingInfo[] });

      const [highTideResult, searchResult] = await Promise.all([
        aggregate,
        assetHazards,
      ]);

      // TODO improve this since we can never call both endpoints for this module

      // combine the two data sets into one so we can assign the correct risks to the correct assets
      const risks = highTideResult.data.concat(searchResult.data);

      return risks;
    }
  );

  return {
    riskRatings: data,
    isLoading: isLoading,
    isError: error,
  };
};
