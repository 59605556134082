import { useEffect } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import type { Control, UseFormSetValue } from "react-hook-form";
import { useController, useWatch } from "react-hook-form";

import { RiskRatingEnum } from "@/apis/services/HazardService";

type RiskRatingMappingType = {
  [key: number]: string;
};

export const riskRatingMapping: RiskRatingMappingType = {
  [9]: RiskRatingEnum.Catastrophic,
  [8]: RiskRatingEnum.VeryHigh,
  [7]: RiskRatingEnum.HighVeryHigh,
  [6]: RiskRatingEnum.High,
  [5]: RiskRatingEnum.MediumHigh,
  [4]: RiskRatingEnum.Medium,
  [3]: RiskRatingEnum.LowMedium,
  [2]: RiskRatingEnum.Low,
  [1]: RiskRatingEnum.VeryLow,
  [0]: RiskRatingEnum.Negligible,
  [-1]: RiskRatingEnum.NotAssessed,
  [-2]: RiskRatingEnum.Plausible,
};

type ThresholdSettingProps = {
  thresholdSettingName: string;
  control: Control<any, any>;
  disabled?: boolean;
};

type LimitedThresholdSettingProps = ThresholdSettingProps & {
  setValue: UseFormSetValue<any>;
};

const ThresholdFormSelect = ({
  thresholdSettingName,
  control,
  disabled,
}: ThresholdSettingProps) => {
  const name = `views.portfolio.settings.risk_threshold.${thresholdSettingName}.unacceptable`;
  const { field } = useController({
    name,
    control,
  });

  return (
    <FormControl fullWidth>
      <InputLabel id="unacceptable_threshold" disabled={disabled}>
        Unacceptable Threshold
      </InputLabel>
      <Select
        disabled={disabled}
        label="Unacceptable Threshold"
        labelId="unacceptable_threshold"
        {...field}
      >
        {Object.keys(riskRatingMapping)
          .reverse()
          .map((key: string) => {
            const index = parseInt(key, 10);
            return (
              <MenuItem key={index} value={index} divider={index === -1}>
                {riskRatingMapping[index]}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

const LimitedThresholdFormSelect = ({
  control,
  setValue,
  thresholdSettingName,
  disabled,
}: LimitedThresholdSettingProps) => {
  const name = `views.portfolio.settings.risk_threshold.${thresholdSettingName}.tolerable`;
  const { field } = useController({
    name,
    control,
  });

  const value = useWatch({
    control,
    name: `views.portfolio.settings.risk_threshold.${thresholdSettingName}.unacceptable`,
  });

  useEffect(() => {
    if (parseInt(field.value) >= value && value > -1) {
      setValue(name, value - 1);
    } else if (value === -1) {
      setValue(name, value);
    }
  }, [value]);

  return (
    <FormControl fullWidth>
      <InputLabel id="tolerable_threshold" disabled={disabled}>
        Tolerable Threshold
      </InputLabel>
      <Select
        label="Tolerable Threshold"
        labelId="tolerable_threshold"
        disabled={value <= 0 || disabled}
        {...field}
      >
        {Object.keys(riskRatingMapping)
          .reverse()
          .filter((k) => parseInt(k) < value || parseInt(k) === -1)
          .map((key: string) => {
            const index = parseInt(key, 10);
            return (
              <MenuItem key={index} value={index} divider={index === -1}>
                <Typography style={{ fontWeight: 0 }}>
                  {riskRatingMapping[index]}
                </Typography>
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export const RiskThresholdSetting = ({
  setValue,
  thresholdSettingName,
  control,
  disabled,
}: LimitedThresholdSettingProps) => {
  return (
    <Stack spacing={2} direction="row">
      <ThresholdFormSelect
        thresholdSettingName={thresholdSettingName}
        control={control}
        disabled={disabled}
      />
      <LimitedThresholdFormSelect
        control={control}
        setValue={setValue}
        thresholdSettingName={thresholdSettingName}
        disabled={disabled}
      />
    </Stack>
  );
};
