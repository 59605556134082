import type { ComputedBarDatum, BarDatum } from "@nivo/bar";

import {
  SubgraphBorderLeft,
  SubgraphBorderTop,
  SubgraphBorderRight,
  SubgraphBorderBottom,
} from "@/components/high-risks/risk-ratings/BarBorderLayer";
import { formatNumber } from "@/utils/utils";

interface Props {
  bars: ComputedBarDatum<BarDatum>[];
  risks: string[];
  isHorizontal: boolean;
  unit: string;
}

export const RiskExposureBarBorderLayer: any = ({
  bars,
  risks,
  isHorizontal,
  unit,
}: Props) => {
  const categories = [
    ...new Set(bars.map((item: any) => item.key.split(".")[1])),
  ];

  const categorizedBars = categories.map((uniqueKey) =>
    bars.filter(
      (bar) =>
        bar.key.includes(uniqueKey) &&
        risks.includes(bar.data.id as string) &&
        bar.data.formattedValue !== "0"
    )
  );

  const totalValuePerBar = categorizedBars.map((category) =>
    category.map((o) => parseFloat(o.data.formattedValue)).reduce(add, 0)
  );

  return categorizedBars
    .map((bars, i) => {
      return bars.map((bar, j) => {
        return isHorizontal
          ? horizontalHighlight(
              bars.length - 1,
              j,
              bar,
              formatNumber(totalValuePerBar[i]),
              unit
            )
          : verticalHighlight(
              bars.length - 1,
              j,
              bar,
              formatNumber(totalValuePerBar[i]),
              unit
            );
      });
    })
    .flat();
};

const add = (accumulator: number, a: number) => {
  return accumulator + a;
};

const horizontalHighlight = (
  lastIndex: number,
  i: number,
  bar: ComputedBarDatum<BarDatum>,
  value: string,
  unit: string
) => (
  <>
    {i === 0 && <SubgraphBorderLeft bar={bar} />}
    <SubgraphBorderTop bar={bar} />
    {i === lastIndex && (
      <>
        <SubGraphNumberRight bar={bar} value={value} unit={unit} />
        <SubgraphBorderRight bar={bar} />
      </>
    )}
    <SubgraphBorderBottom bar={bar} />
  </>
);

const verticalHighlight = (
  lastIndex: number,
  i: number,
  bar: ComputedBarDatum<BarDatum>,
  value: string,
  unit: string
) => (
  <>
    <SubgraphBorderLeft bar={bar} />
    {i === 0 && <SubgraphBorderTop bar={bar} />}
    <SubgraphBorderRight bar={bar} />
    {i === lastIndex && (
      <>
        <SubGraphNumberTop bar={bar} value={value} unit={unit} /> &&{" "}
        <SubgraphBorderBottom bar={bar} />
      </>
    )}
  </>
);

const SubGraphNumberTop = ({ bar, value, unit }: any) => (
  <text
    textAnchor="middle"
    transform={`translate(${bar.x + bar.width / 2}, ${bar.y - 5})`}
    key={bar.key + "_total_value"}
    fontSize={11}
    fontWeight="bold"
  >
    {value}
    {` `}
    {unit}
  </text>
);

const SubGraphNumberRight = ({ bar, value, unit }: any) => (
  <text
    textAnchor="start"
    transform={`translate(${bar.x + bar.width + 5}, ${bar.y + bar.height / 2})`}
    key={bar.key + "_total_value"}
    fontSize={11}
    fontWeight="bold"
  >
    {value}
    {` `}
    {unit}
  </text>
);
