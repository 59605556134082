import { Stack, TableCell, Typography } from "@mui/material";

import { InformativeTextPopover } from "@/components/common/InformativeTextPopover";

interface TableCellHeaderProps {
  text: string;
  info?: string;
}

export const TableCellHeader = (props: TableCellHeaderProps) => {
  const { text, info } = props;
  return (
    <TableCell>
      <Stack direction="row" spacing={1}>
        <Typography variant="h3" fontSize="1rem">
          {text}
        </Typography>
        {info && (
          <InformativeTextPopover text={info} hover width="24%" isInfo />
        )}
      </Stack>
    </TableCell>
  );
};
