import {
  Stack,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
} from "@mui/material";

import { DefaultLoader } from "@/components/common/loading/DefaultLoader";

export const HighRisksTableLoading = () => {
  return (
    <TableContainer
      data-test="high-risks-table-loading"
      component={Paper}
      sx={{
        boxShadow: "none",
        overflowX: { xl: "hidden " },
      }}
    >
      <Table size="small" aria-label="high risk table loading">
        <TableBody>
          {[0, 1, 2, 3, 4].map((index) => (
            <TableRow key={index}>
              <TableCell colSpan={999}>
                <Stack spacing={0.5}>
                  <DefaultLoader key={index} height={30} />
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
