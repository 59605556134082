import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { HazardFilter } from "@/components/asset/filter/HazardFilter";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { prepareListByGroup } from "@/components/high-risks/risk-ratings/helperFunctionsOfIndividualAssetRiskRating";
import { HAZARD_TYPES_BY_GROUP } from "@/components/high-risks/types";
import { ALL_HAZARDS } from "@/constants/risks";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

interface Props {
  control: Control;
  isHazardGraph?: boolean;
}

export const HazardFilterController = ({ control, isHazardGraph }: Props) => {
  const { data: id } = useUserInfo();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const testId = `hazard-filter-controller${
    isHazardGraph ? "-by-hazard" : ""
  }-select`;

  return (
    <Controller
      control={control}
      name="hazard-filter"
      render={({ field: { onChange, value } }) => (
        <HazardFilter
          value={value}
          onChange={(hazard) =>
            onChange(hazard.length ? hazard : [ALL_HAZARDS])
          }
          hazards={prepareListByGroup(Object.keys(HAZARD_TYPES_BY_GROUP), true)}
          options={{
            multiple: isHazardGraph,
            showAggregate: !isHazardGraph,
            hideIcon: true,
            showAll: isHazardGraph ? "all hazards" : undefined,
            dataTest: testId,
            useOpenSearch: useOpenSearch,
          }}
          styles={{
            formStyles: {
              minWidth: "0px!important",
              maxWidth: "500px!important",
            },
            selectStyles: HoverTextSelectStyles(),
          }}
        />
      )}
    />
  );
};
