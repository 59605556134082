import { Box, Skeleton, Typography } from "@mui/material";

import { DASHED_BORDER } from "@/styles/BorderStyle";

export interface RiskCountByHazardLoadingProps {
  text?: string;
}

export const RiskCountByHazardLoading = (
  props: RiskCountByHazardLoadingProps
) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight={250}
      width="100%"
      sx={{ ...DASHED_BORDER, pt: 1 }}
      data-test="loading-risk-count-view"
    >
      <Skeleton variant="text" width={400} />
      <Skeleton variant="text" width={400} />
      <Skeleton variant="text" width={400} />
      <Skeleton variant="text" width={400} />
      <Skeleton variant="text" width={400} />
      <Typography fontWeight="300" fontStyle="italic" pt={2}>
        {props.text ?? "Loading results..."}
      </Typography>
    </Box>
  );
};
