import { faBug } from "@fortawesome/free-solid-svg-icons/faBug";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

import { DASHED_BORDER } from "@/styles/BorderStyle";

export interface RiskCountByHazardErrorProps {
  text?: string;
}

export const RiskCountByHazardError = (props: RiskCountByHazardErrorProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      minHeight={250}
      width="100%"
      sx={{ ...DASHED_BORDER, pt: 1 }}
      data-test="loading-risk-count-view"
    >
      <FontAwesomeIcon size="3x" icon={faBug} color="grey" />
      <Typography fontWeight="300" fontStyle="italic" pt={2}>
        {props.text ?? "There was an error loading the results."}
      </Typography>
    </Box>
  );
};
