import { TableCell } from "@mui/material";

interface RiskDistributionHeaderCellProps {
  text: string;
}

export const RiskDistributionHeaderCell = (
  props: RiskDistributionHeaderCellProps
) => {
  const { text } = props;

  return (
    <TableCell
      style={{
        minWidth: "110px",
        textAlign: "center",
        borderRight: text === "Catastrophic" ? "" : "1px solid lightgray",
      }}
    >
      {text}
    </TableCell>
  );
};
