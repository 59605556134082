import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { SettingNameEnum } from "@/apis/services/OrganizationService";
import { ConsequenceFilter } from "@/components/asset/filter/ConsequenceFilter";
import { HoverTextSelectStyles } from "@/components/high-risks/exposure/HoverTextSelectStyles";
import { useGetFeatureFlags } from "@/hooks/useFlags";
import { useUserInfo } from "@/hooks/useUserInfo";
import { isFlagEnabled } from "@/utils/flags/flags-utils";

interface Props {
  control: Control;
  isHazardGraph?: boolean;
}

export const ConsequenceFilterController = ({
  control,
  isHazardGraph,
}: Props) => {
  const { data: id } = useUserInfo();

  const orgId = id?.user?.organization?.id ?? "";
  const { data: flags } = useGetFeatureFlags(orgId);
  const useOpenSearch = isFlagEnabled(flags, "useOpenSearch");

  const testId = `consequence-filter-controller${
    isHazardGraph ? "-by-hazard" : ""
  }-select`;

  return (
    <Controller
      control={control}
      name="consequence-filter"
      render={({ field: { onChange, value } }) => (
        <ConsequenceFilter
          value={value}
          onChange={(consequence) => onChange(consequence)}
          setting={SettingNameEnum.RiskRatingsGraph}
          options={{
            multiple: false,
            showHazardRating: true,
            hideIcon: true,
            showAll: "all consequences",
            dataTest: testId,
            useOpenSearch: useOpenSearch,
          }}
          styles={{
            formStyles: {
              minWidth: "0px!important",
              maxWidth: "500px!important",
            },
            selectStyles: HoverTextSelectStyles(),
          }}
        />
      )}
    />
  );
};
